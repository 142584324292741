document.addEventListener("DOMContentLoaded", () => {
  function attachCloseHandler(dialog) {
    dialog.addEventListener("close", () => {
      dialog.classList.add("message--closing");
      dialog.addEventListener("animationend", () => {
        dialog.classList.remove("message--closing");
      });
    });
  }

  document.querySelectorAll("dialog.message").forEach((dialog) => {
    attachCloseHandler(dialog);
  });

  document
    .getElementById("message-container")
    .addEventListener("htmx:afterProcessNode", (event) => {
      attachCloseHandler(event.detail.elt);
    });
});
